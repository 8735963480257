import {SlotMachine} from "./SlotMachine";
import * as PIXI from "pixi.js";
import {Sprite} from "pixi.js";
import {Cords, Hidden, Reels, WinLineEvent} from "../../api/types";
import {SYMBOL_SIZE, SYMBOLS} from "../constants/Symbols";
import {SymbolItem} from "./SymbolItem";
import gsap from "gsap";
import {Multiplier} from "./multiplier";
import {infoValueStylesLandscape, multiplierStylesLeft} from "../styles";

export class SlotEvents {
    slotMachine: SlotMachine
    stickySymbolsContainer: PIXI.Container
    stickySymbols: SymbolItem[][] | null[][]

    constructor(slotMachine: SlotMachine) {
        this.slotMachine = slotMachine
        this.stickySymbolsContainer = new PIXI.Container()
        this.stickySymbols = [[null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null]]
        this.slotMachine.reelsContainer.addChild(this.stickySymbolsContainer)
        this.stickySymbolsContainer.zIndex = 100
    }

    placeWinBorder = (symbol: Sprite, color = 0x00e63d) => {
        const border = new PIXI.Graphics();
        border.lineStyle(16, color, 1);
        border.beginFill(131, 0)
        border.drawRect(8, 8, 240, 240);
        border.endFill();
        symbol.addChild(border);
    }


    buildWinLine = (reels: Reels, eventsLeft: WinLineEvent[], eventsRight: WinLineEvent[]) => {
        const winBar = this.slotMachine.game.assetsManager.infoBar?.winBar
        if (!winBar) return
        return new Promise((resolve) => {
            if (eventsLeft.length) {
                eventsLeft.forEach((event) => {
                    event.coords.forEach((cords) => {
                        if (this.slotMachine.reels[cords.x].symbols[cords.y]) {
                            this.placeWinBorder(this.slotMachine.reels[cords.x].symbols[cords.y].sprite)
                        }
                    })
                })
            }

            if (eventsRight.length) {
                eventsRight.forEach((event) => {
                    event.coords.forEach((cords) => {
                        if (this.slotMachine.reels[cords.x].symbols[cords.y]) {
                            this.placeWinBorder(this.slotMachine.reels[cords.x].symbols[cords.y].sprite)
                        }
                    })

                })
            }


            winBar.changeValue(reels.winSum)


            const timer = setTimeout(() => {
                clearTimeout(timer)
                return resolve(true)
            }, 1000 * (reels.winSum ? 1 : 0))
        })
    }

    stickSymbols = (possitionsHidden?: Hidden[], scatters?: Cords[]) => {
        if (!possitionsHidden || !possitionsHidden.length) return

        return new Promise(async (resolve, reject) => {
            let hiddens = 0
            const timeLine = gsap.timeline()
            timeLine.fromTo(this.stickySymbolsContainer,
                {
                    alpha: this.stickySymbolsContainer.alpha
                },
                {alpha: 1, duration: 0.2}
            )
            for (let i = 0; i < possitionsHidden.length; i++) {
                const symbol = possitionsHidden[i]
                if (symbol.persistent) {
                    hiddens++
                    const foundSymbol = SYMBOLS.find((s) => s.name === 'HIDDEN')
                    const currentSymbol = this.slotMachine.reels[symbol.x].symbols[symbol.y]
                    currentSymbol.sprite.zIndex = 100
                    if (foundSymbol && currentSymbol) {
                        const newSymbol = new SymbolItem(foundSymbol.id, foundSymbol.name, foundSymbol.asset)
                        const timeline = gsap.timeline()

                        if (newSymbol) {
                            newSymbol.sprite.x = currentSymbol.sprite.x
                            newSymbol.sprite.y = currentSymbol.sprite.y
                            // this.slotMachine.reels[symbol.x].symbols[symbol.y] = newSymbol
                            this.stickySymbols[symbol.x][symbol.y] = newSymbol
                            this.stickySymbolsContainer.addChild(newSymbol.sprite)
                        }
                        timeline.fromTo(currentSymbol.sprite, {alpha: 1}, {alpha: 0, duration: 0.3})


                    }
                }
            }

            // if(scatters && scatters?.length > 0) {
            //     for (let i = 0; i < scatters.length; i++) {
            //         const symbol = scatters[i]
            //         if (symbol) {
            //             hiddens++
            //             const foundSymbol = SYMBOLS.find((s) => s.name === 'SCATTER')
            //             const currentSymbol = this.slotMachine.reels[symbol.x].symbols[symbol.y]
            //             currentSymbol.sprite.zIndex = 100
            //             if (foundSymbol && currentSymbol) {
            //                 const newSymbol = new SymbolItem(foundSymbol.id, foundSymbol.name, foundSymbol.asset)
            //                 const timeline = gsap.timeline()
            //
            //                 if (newSymbol) {
            //                     newSymbol.sprite.x = currentSymbol.sprite.x
            //                     newSymbol.sprite.y = currentSymbol.sprite.y
            //                     // this.slotMachine.reels[symbol.x].symbols[symbol.y] = newSymbol
            //                     this.stickySymbols[symbol.x][symbol.y] = newSymbol
            //                     this.stickySymbolsContainer.addChild(newSymbol.sprite)
            //                 }
            //                 timeline.fromTo(currentSymbol.sprite, {alpha: 1}, {alpha: 0, duration: 0.3})
            //
            //
            //             }
            //         }
            //     }
            // }

            const timer = setTimeout(() => {

                clearTimeout(timer)
            }, 300 * (hiddens ? 1 : 0))
            await this.slotMachine.dropSymbolsToBottom()
            await this.cascadeStickySymbols()

            return resolve(true)
        })
    }

    clearStickySymbols = () => {
        console.log(`CLEAR STICKY`)
        return new Promise((resolve, reject) => {
            this.slotMachine.reels.forEach((reel) => {
                reel.symbols.forEach((symbol) => {
                    symbol.sprite.alpha = 1
                })
            })
            this.stickySymbols.forEach((symbols) => {
                symbols.forEach((symbol, idx) => {
                    if (!symbol) return
                    symbol.sprite.destroy()
                    symbols[idx] = null
                })
            })

            this.stickySymbolsContainer.removeChildren()

            return resolve(true)
        })


    }

    placeMultiplier = (symbol: Sprite, mult: number) => {
        const multiplier = new PIXI.Text(`x${mult}`, infoValueStylesLandscape)
        multiplier.anchor.set(0.5, 0.5)
        multiplier.x = 140
        multiplier.y = 190
        multiplier.zIndex = 1000
        symbol.addChild(multiplier);
    }

    cascadeStickySymbols = () => {

        return new Promise((resolve, reject) => {
            let hiddens = 0
            for (let i = 0; i < this.stickySymbols.length; i++) {
                const reel = this.stickySymbols[i]

                reel.sort((a: SymbolItem | null, b: SymbolItem | null) => !a ? -1 : 1)

                for (let j = reel.length - 1; j >= 0; j--) {
                    const symbol = reel[j]
                    if (symbol) {
                        hiddens++
                        const timeline = gsap.timeline()
                        const startY = reel.length === 3 ? SYMBOL_SIZE + 4 : 0
                        timeline.fromTo(symbol.sprite, {y: symbol.sprite.y}, {
                            y: startY + (SYMBOL_SIZE * j) + ((j + 1) * 4),
                            duration: 0.2
                        })
                    }
                }
            }

            const timer = setTimeout(() => {
                clearTimeout(timer)

                resolve(true)
            }, 200 * (hiddens ? 1 : 0))
        })
    }

    replaceToScatter = (scatters: Cords[], hiddens: Hidden[]) => {
        if(!scatters || scatters.length < 2) return
        return new Promise((resolve, reject) => {


            if(hiddens.length > 0) {
                hiddens.forEach((cords) => {
                    const foundSymbol = SYMBOLS.find((s) => s.name === "SCATTER")
                    const currentSymbol = this.slotMachine.reels[cords.x].symbols[cords.y]
                    if (foundSymbol) {
                        const newSymbol = new SymbolItem(foundSymbol.id, foundSymbol.name, foundSymbol.asset)
                        const timeline = gsap.timeline()
                        currentSymbol.sprite.zIndex = 100
                        if (newSymbol) {
                            newSymbol.sprite.x = currentSymbol.sprite.x
                            newSymbol.sprite.y = currentSymbol.sprite.y
                            this.slotMachine.reels[cords.x].symbols[cords.y] = newSymbol
                            this.slotMachine.reels[cords.x].reelsContainer.addChild(newSymbol.sprite)
                        }
                        timeline.fromTo(currentSymbol.sprite, {alpha: 1}, {alpha: 0, duration: 0.3})

                    }
                })
            }

            const timer = setTimeout(async() => {
                clearTimeout(timer)
                await this.showAllScatters(scatters, hiddens)
                return resolve(true)
            }, 500)
        })
    }

    showAllScatters = (scatters: Cords[], hiddens: Hidden[]) => {
        return new Promise((resolve) => {

            scatters.forEach((cords) => {
                if(this.slotMachine.reels[cords.x].symbols[cords.y]) {
                    const symbol = this.slotMachine.reels[cords.x].symbols[cords.y]
                    this.placeWinBorder(symbol.sprite, 0xffffff)
                }
            })

            if(hiddens.length > 0) {
                hiddens.forEach((hidden) => {
                    if(this.slotMachine.reels[hidden.x].symbols[hidden.y]) {
                        const symbol = this.slotMachine.reels[hidden.x].symbols[hidden.y]
                        this.placeWinBorder(symbol.sprite, 0xffffff)
                    }
                })
            }

            const timer = setTimeout(() => {
                clearTimeout(timer)
                return resolve(true)
            }, 1000)
        })
    }
}
