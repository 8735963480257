import {Game} from "../Game";
import {Reel} from "./Reel";
import {SYMBOL_SIZE, SYMBOLS, TEST_SYMBOLS} from "../constants/Symbols";
import gsap from 'gsap';
import * as PIXI from 'pixi.js'
import {Texture} from "pixi.js";
import {UI_ASSETS} from "../assetsLoader/UiAssets";
import {ApiResponse, Hidden, PlayResponse, Rounds} from "../../api/types";
import {api} from "../../App";
import {SymbolItem} from "./SymbolItem";
import {SlotEvents} from "./SlotEvents";
import {testData} from "../constants/tests";

export class SlotMachine {
    game: Game
    reels: Reel[]
    isSpinning: boolean
    reelsContainer: PIXI.Container
    balance: number
    bet: number
    symbols: string[][]
    spinData?: ApiResponse<PlayResponse>
    rounds?: Rounds[]
    slotEvents: SlotEvents
    index: number
    isFs: boolean
    fsCount: number
    prevSpin: "spin" | "freeSpin"
    constructor(game: Game) {
        this.game = game
        this.reels = []
        this.isSpinning = false
        this.reelsContainer = new PIXI.Container()
        this.reelsContainer.sortableChildren = true
        this.balance = 10000
        this.bet = this.game.assetsManager.infoBar?.betBar.currentBetValue!
        this.symbols = []
        this.slotEvents = new SlotEvents(this)
        this.index = 1000
        this.isFs = false
        this.fsCount = 0
        this.prevSpin = "spin"
    }

    init = async () => {
        if (!this.game.assetsManager.gameField) return
        this.reelsContainer.width = this.game.assetsManager.gameField.width
        this.reelsContainer.height = this.game.assetsManager.gameField.height
        this.game.assetsManager.gameField.container?.addChild(this.reelsContainer)

        const mask = new PIXI.Sprite(Texture.from(UI_ASSETS.SHEET_MASK))
        mask.width = this.game.assetsManager.gameField.width
        mask.height = this.game.assetsManager.gameField.height
        mask.zIndex = 1000
        this.reelsContainer.mask = mask
        this.reelsContainer.addChild(mask)

        for (let i = 0; i < TEST_SYMBOLS.length; i++) {
            const symbols = TEST_SYMBOLS[i]
            const reel = new Reel(this.game, i, this.reelsContainer)
            await reel.init()
            await reel.generateSymbols(symbols)
            await reel.placeSymbols(i)
            this.reels?.push(reel)
        }


        this.game.assetsManager.infoBar?.balanceBar.changeValue(this.balance)
        document.addEventListener('keydown', async(e) => {
            if (e.code === 'Space') {
                // for(let i = 0; this.index > 0; i++) {
                //     this.index--
                    await this.onSpin()
                // }
            }
            if(e.code === "Escape") this.resetIndex()
        })
    }

    resetIndex = () => {
        this.index = 1
    }


    onSpin = async (spinData?: ApiResponse<PlayResponse>) => {

        if (this.isSpinning) return
        this.isSpinning = true
        this.game.assetsManager.changeButtonsState(false)
        this.game.assetsManager.BuyBonus1?.disableButton(true)
        this.game.assetsManager.BuyBonus2?.disableButton(true)

        this.changeBalance(-this.bet)

        if(this.game.isDebugReels) {
            this.prevSpin = 'spin'
            this.spinData = await api.auth.debug(this.bet)
        } else if(spinData) {
            this.spinData = spinData
        }
        else {
            if(this.isFs) {
                this.fsCount--
                this.spinData = await api.auth.play(this.bet, this.isFs, 0)
                this.game.assetsManager.playBtn?.setSpinCount(this.fsCount)
                if(this.spinData?.playerState.playerStatePrivate.freeSpinCount <= 1) {
                    this.isFs = false
                    this.fsCount = 0
                }
                this.prevSpin = "freeSpin"
            } else {
                this.prevSpin = 'spin'
                this.spinData = await api.auth.play(this.bet, this.isFs, 0)
                // this.spinData = testData
            }


        }

        if(!this.spinData) return

        if(this.spinData.results[0].clientData.rounds[0].reels.type !== "bonusType2" || spinData) {
            await this.game.assetsManager.multiplier.changeLeftMult(1)
            await this.game.assetsManager.multiplier.changeRightMult(1)
        }

        await this.dropSymbolsToBottom()

        this.game.assetsManager.infoBar?.winBar.changeValue(0)

        this.rounds = this.spinData?.results[0].clientData.rounds!
        // this.rounds = testData
        for (let i = 0; i < this.rounds.length; i++) {
            const round = this.rounds[i]
            this.symbols = round?.reels.positionsHidden
            if(i !== 0 ) {
                await this.dropSymbolsToBottom()
            }
            await this.resetSymbolPositions()

            await this.changeSymbols()
            await this.dropSymbolsFromTop()
            if(i === this.rounds.length - 1 && !this.isFs) {
                await this.slotEvents.clearStickySymbols()
            }

            const hiddenSymbols = round.reels.hidden
            this.slotEvents.stickySymbolsContainer.alpha = 0
            await this.changeHiddenSymbols(hiddenSymbols)

            await this.slotEvents.buildWinLine(round.reels, round.reels.eventsLeft, round.reels.eventsRight)
            await this.game.assetsManager.multiplier.changeLeftMult(round.reels.multLeft)
            await this.game.assetsManager.multiplier.changeRightMult(round.reels.multRight)
            await this.slotEvents.replaceToScatter(round.reels.scatter, round.reels.hidden)
            if(i !== this.rounds.length - 1) {
                await this.slotEvents.stickSymbols(hiddenSymbols, round.reels.scatter)
            }

        }

        this.balance = this.spinData.results[0].clientData.account.value
        this.changeBalance()
        if(this.isFs && this.fsCount > 0) {
            const timer = setTimeout(async() => {
                this.isSpinning = false
                this.balance = this.spinData?.results[0].clientData.account.value!
                this.changeBalance()
                this.slotEvents.stickySymbolsContainer.alpha = 1
                this.onSpin()
                clearTimeout(timer)
            }, 1000)
        } else {
            this.game.assetsManager.playBtn?.changeButtonType('default')
            if(this.spinData.playerState.playerStatePrivate.freeSpinCount > 1) {
                this.game.assetsManager.FsModal.showModal(true, this.spinData.playerState.playerStatePrivate.freeSpinCount)
            }
            if(this.spinData.playerState.playerStatePrivate.freeSpinCount === 1) {
                this.game.assetsManager.totalWinModal.showModal(true, this.spinData.results[0].freeGameTotalWins)
            }
            this.isSpinning = false
            this.game.assetsManager.changeButtonsState(true)
            this.game.assetsManager.BuyBonus1?.disableButton(false)
            this.game.assetsManager.BuyBonus2?.disableButton(false)
            this.balance = this.spinData?.results[0].clientData.account.value!
        }


    }

    changeSymbols = () => {
        if (!this.symbols.length || !this.reels?.length) return
        return new Promise(async (resolve) => {
            for (let i = 0; i < 7; i++) {
                const symbols = this.symbols[i]
                const reel = new Reel(this.game, i, this.reelsContainer)
                await reel.init()
                await reel.generateSymbols(symbols)
                await reel.placeSymbols(i, false)
                await reel.clearWinBorder()
                this.reels[i] = reel
                reel.symbols.forEach((symbol,idx) => {
                    if(this.slotEvents.stickySymbols[i][idx]) {
                        symbol.sprite.alpha = 0
                    }
                })
            }
            return resolve(true)
        })
    }


    dropSymbolsToBottom = () => {
        return new Promise((resolve, reject) => {
            let reelIdx = 0
            if (!this.reels?.length) return reject('REELS IS EMPTY')
            const reels = this.reels

            const interval = setInterval(() => {
                const reel = reels[reelIdx]
                if (reelIdx === reels.length) {
                    clearInterval(interval)
                    const timer = setTimeout(() => {
                        clearTimeout(timer)
                        return resolve(true)
                    }, 1000)
                } else {


                    for (let i = 0; i < reel.symbols.length; i++) {
                        const symbol = reel.symbols[i]
                        const timeline = gsap.timeline()
                        timeline.fromTo(
                            symbol.sprite,
                            {y: symbol.sprite.y},
                            {duration: 0.5, y: symbol.sprite.y += 1260, delay: 0.04 * ((reel.symbols.length - 1) - i)}
                        )
                    }
                    reelIdx++
                }
            }, 40)
        })
    }

    dropSymbolsFromTop = () => {
        return new Promise((resolve, reject) => {
            let reelIdx = 0;
            if (!this.reels?.length) return reject('REELS IS EMPTY')
            const reels = this.reels

            const interval = setInterval(() => {
                const reel = reels[reelIdx]
                if (reelIdx === reels.length) {
                    clearInterval(interval)
                    const timer = setTimeout(() => {
                        clearTimeout(timer)
                        return resolve(true)
                    }, 1000)
                } else {


                    for (let i = 0; i < reel.symbols.length; i++) {
                        const symbol = reel.symbols[i]
                        const timeline = gsap.timeline()
                        const startY = reel.symbols.length === 3 ? SYMBOL_SIZE + 4 : 0

                        timeline.fromTo(
                            symbol.sprite,
                            {y: symbol.sprite.y},
                            {
                                duration: 0.5,
                                y: startY + (SYMBOL_SIZE * i) + ((i + 1) * 4),
                                delay: 0.04 * ((reel.symbols.length - 1) - i)
                            }
                        )
                    }
                    reelIdx++
                }
            }, 40)

        })
    }

    resetSymbolPositions = () => {
        return new Promise((resolve, reject) => {
            this.reels?.forEach(async (reel, reelIdx) => {
                await reel.replaceTopTop(reelIdx)
            })

            this.reelsContainer.sortChildren()

            return resolve(true)
        })
    }

    changeBalance = (value = 0) => {
        if(this.isFs) value = 0
        this.balance += value
        this.game.assetsManager.infoBar?.balanceBar.changeValue(this.balance)
    }


    changeHiddenSymbols = (possitionsHidden?: Hidden[]) => {
        if (!possitionsHidden || !possitionsHidden.length) return
        return new Promise((resolve, reject) => {
            let hiddens = 0
            possitionsHidden.forEach(async (symbol) => {
                const foundSymbol = SYMBOLS.find((s) => s.name === symbol.changeTo)
                const currentSymbol = this.reels[symbol.x].symbols[symbol.y]
                if (foundSymbol) {
                    const newSymbol = new SymbolItem(foundSymbol.id, foundSymbol.name, foundSymbol.asset)
                    if(symbol.changeTo === "WILD") {
                        this.slotEvents.placeMultiplier(newSymbol.sprite, symbol.multi)
                    }
                    const timeline = gsap.timeline()
                    currentSymbol.sprite.zIndex = 100
                    if (newSymbol) {
                        hiddens ++
                        newSymbol.sprite.x = currentSymbol.sprite.x
                        newSymbol.sprite.y = currentSymbol.sprite.y
                        this.reels[symbol.x].symbols[symbol.y] = newSymbol
                        this.reels[symbol.x].reelsContainer.addChild(newSymbol.sprite)
                    }
                     timeline.fromTo(currentSymbol.sprite, {alpha: 1}, {alpha: 0, duration: 0.3})

                }

            })

            const timer = setTimeout(() => {
                clearTimeout(timer)

                resolve(true)
            }, 500 * (hiddens ? 1 : 0))
        })
    }


}
