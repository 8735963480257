import * as PIXI from 'pixi.js'
import {Game} from "../Game";
import { multiplierStylesLeft, multiplierStylesRight } from '../styles';
import {BUTTONS_ASSETS} from "../assetsLoader/ButtonsLoader";
import {Texture} from 'pixi.js';

export class Multiplier {
    game: Game
    leftMultiplier: PIXI.Text
    rightMultiplier: PIXI.Text
    leftArrow?: PIXI.Sprite
    rightArrow?: PIXI.Sprite
    leftMultiplierValue: number
    rightMultiplierValue: number
    leftMultiplierContainer: PIXI.Container
    rightMultiplierContainer: PIXI.Container
    leftArrowContainer: PIXI.Container
    rightArrowContainer: PIXI.Container
    leftMultiplierBG: PIXI.Graphics
    rightMultiplierBG: PIXI.Graphics
    leftArrowBG: PIXI.Graphics
    rightArrowBG: PIXI.Graphics

    constructor(game: Game) {
        this.game = game
        this.leftMultiplierContainer = new PIXI.Container()
        this.rightMultiplierContainer = new PIXI.Container()
        this.leftArrowContainer = new PIXI.Container()
        this.rightArrowContainer = new PIXI.Container()

        this.leftMultiplierValue = 1
        this.rightMultiplierValue = 1
        this.leftMultiplier = new PIXI.Text(`X${this.leftMultiplierValue}`, multiplierStylesLeft);
        this.rightMultiplier = new PIXI.Text(`X${this.rightMultiplierValue}`, multiplierStylesRight);
        this.leftMultiplierBG = new PIXI.Graphics();
        this.rightMultiplierBG = new PIXI.Graphics();
        this.leftArrowBG = new PIXI.Graphics();
        this.rightArrowBG = new PIXI.Graphics();

    }

    init = async () => {
        this.leftArrow = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.LEFT_BTN_ARROW));
        this.rightArrow = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.RIGHT_BTN_ARROW));
        await this.initLeftMult();
        await this.initLeftArrow();
        await this.initRightMult();
        await this.initRightArrow();
    }

    initLeftMult = async () => {

        this.leftMultiplierContainer.x = 135;
        this.leftMultiplierContainer.y = 120;

        this.leftMultiplierBG.beginFill(0x757171, 1);
        this.leftMultiplierBG.drawRect(-130, -115, 247, 247);
        this.leftMultiplierBG.endFill();

        this.game.assetsManager.gameField!.container?.addChild(this.leftMultiplierContainer)
        this.leftMultiplierContainer.addChild(this.leftMultiplierBG);
        this.leftMultiplierBG.addChild(this.leftMultiplier);

        this.leftMultiplier.resolution = 2;
        this.leftMultiplier.anchor.set(0.5, 0.5);
        this.leftMultiplier.x = 0;
        this.leftMultiplier.y = 0;
    }

    initLeftArrow = async () => {
        if(!this.leftArrow) return
        this.leftArrowContainer.x = 135;
        this.leftArrowContainer.y = 1128;

        this.leftArrowBG.beginFill(0x757171, 1);
        this.leftArrowBG.drawRect(-130, -115, 247, 247);
        this.leftArrowBG.endFill();

        this.game.assetsManager.gameField!.container?.addChild(this.leftArrowContainer)
        this.leftArrowContainer.addChild(this.leftArrowBG);
        this.leftArrowBG.addChild(this.leftArrow);

        this.leftArrow.x = -135;
        this.leftArrow.y = -110;
        this.leftArrow.width = 247;
        this.leftArrow.height = 247;
    }

    initRightMult = async () => {
        this.rightMultiplierContainer.x = 1645;
        this.rightMultiplierContainer.y = 120;

        this.rightMultiplierBG.beginFill(0x757171, 1);
        this.rightMultiplierBG.drawRect(-126, -115, 247, 247);
        this.rightMultiplierBG.endFill();

        this.game.assetsManager.gameField!.container?.addChild(this.rightMultiplierContainer)
        this.rightMultiplierContainer.addChild(this.rightMultiplierBG);
        this.rightMultiplierBG.addChild(this.rightMultiplier);

        this.rightMultiplier.resolution = 2;
        this.rightMultiplier.anchor.set(0.5, 0.5);
        this.rightMultiplier.x = 0;
        this.rightMultiplier.y = 0;
    }

    initRightArrow = async () => {
        if(!this.rightArrow) return
        this.rightArrowContainer.x = 1647;
        this.rightArrowContainer.y = 1128;

        this.rightArrowBG.beginFill(0x757171, 1);
        this.rightArrowBG.drawRect(-130, -115, 247, 247);
        this.leftArrowBG.endFill();

        this.game.assetsManager.gameField!.container?.addChild(this.rightArrowContainer)
        this.rightArrowContainer.addChild(this.rightArrowBG);
        this.rightArrowBG.addChild(this.rightArrow);

        this.rightArrow.x = -130;
        this.rightArrow.y = -120;
        this.rightArrow.width = 247;
        this.rightArrow.height = 247;
    }

    changeLeftMult =async (newVal:number) => {
        this.leftMultiplierValue = newVal
        this.leftMultiplierBG.removeChildren()

        this.leftMultiplier = new PIXI.Text(`X${this.leftMultiplierValue}`, multiplierStylesLeft);
        this.leftMultiplier.anchor.set(0.5, 0.5);
        this.leftMultiplier.x = 0;
        this.leftMultiplier.y = 0;
        this.leftMultiplierBG.addChild(this.leftMultiplier)
    }

    changeRightMult =async (newVal:number) => {
        this.rightMultiplierValue = newVal
        this.rightMultiplierBG.removeChildren()

        this.rightMultiplier = new PIXI.Text(`X${this.rightMultiplierValue}`, multiplierStylesRight);
        this.rightMultiplier.anchor.set(0.5, 0.5);
        this.rightMultiplier.x = 0;
        this.rightMultiplier.y = 0;
        this.rightMultiplierBG.addChild(this.rightMultiplier)
    }
}
