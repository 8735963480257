import {Loader} from "pixi.js";
import PlusBtn from '../assets/info-bar/btn-plus-default.svg'
import PlusBtnHover from '../assets/info-bar/btn-plus-hover.svg'
import MinusBtn from '../assets/info-bar/btn-minus-default.svg'
import MinusBtnHover from '../assets/info-bar/btn-minus-hover.svg'
import PlayBtnIcon from '../assets/buttons/play-btn-icon.png'
import PlayBtnIconHover from '../assets/buttons/play-btn-icon-hover.png'
import PlayBtnBg from '../assets/buttons/play-btn-background.png'
import PlayBtnBorder from '../assets/buttons/play-btn-border.png'
import StopBtnIcon from '../assets/buttons/stop-btn-icon.png'
import StopBtnIconHover from '../assets/buttons/stop-btn-icon-hover.png'
import leftArrowBtn from '../assets/buttons/leftArrow.png'
import rightArrowBtn from '../assets/buttons/rightArrow.png'
import BuBonusBg from '../assets/buttons/btn-bonus-background.svg'
import BuyBonusBorder from '../assets/buttons/btn-bonus-default.png'
import BuyBonusHover from '../assets/buttons/btn-bonus-hover.png'
import BuyBonusDisabled from '../assets/buttons/btn-bonus-disabled.png'
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export enum BUTTONS_ASSETS {
    PLUS_BTN = 'PLUS_BTN',
    PLUS_BTN_HOVER = "PLUS_BTN_HOVER",
    MINUS_BTN = "MINUS_BTN",
    MINUS_BTN_HOVER = "MINUS_BTN_HOVER",
    PLAY_BTN_BG = "PLAY_BTN_BG",
    PLAY_BTN_BORDER = "PLAY_BTN_BORDER",
    PLAY_BTN_ICON = "PLAY_BTN_ICON",
    PLAY_BTN_ICON_HOVER = "PLAY_BTN_ICON_HOVER",
    STOP_BTN_ICON = "STOP_BTN_ICON",
    STOP_BTN_ICON_HOVER = "STOP_BTN_ICON_HOVER",
    LEFT_BTN_ARROW = "LEFT_BTN_ARROW",
    RIGHT_BTN_ARROW = "RIGHT_BTN_ARROW",
    BUY_BONUS_HOVER = "BUY_BONUS_HOVER",
    BUY_BONUS_BG = "BUY_BONUS_BG",
    BUY_BONUS_DEFAULT = "BUY_BONUS_DEFAULT",
    BUY_BONUS_DISABLED = "BUY_BONUS_DISABLED"
}

export const loadButtonsAssets = (loader: Loader) => {
    loader.add(BUTTONS_ASSETS.PLUS_BTN, PlusBtn)
    loader.add(BUTTONS_ASSETS.PLUS_BTN_HOVER, PlusBtnHover)
    loader.add(BUTTONS_ASSETS.MINUS_BTN, MinusBtn)
    loader.add(BUTTONS_ASSETS.MINUS_BTN_HOVER, MinusBtnHover)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_BG, PlayBtnBg)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_BORDER, PlayBtnBorder)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_ICON, PlayBtnIcon)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_ICON_HOVER, PlayBtnIconHover)
    loader.add(BUTTONS_ASSETS.STOP_BTN_ICON, StopBtnIcon)
    loader.add(BUTTONS_ASSETS.STOP_BTN_ICON_HOVER, StopBtnIconHover)
    loader.add(BUTTONS_ASSETS.LEFT_BTN_ARROW, leftArrowBtn)
    loader.add(BUTTONS_ASSETS.RIGHT_BTN_ARROW, rightArrowBtn)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_BG, BuBonusBg)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_DEFAULT, BuyBonusBorder)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_HOVER, BuyBonusHover)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_DISABLED, BuyBonusDisabled)

}
