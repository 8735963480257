import * as PIXI from 'pixi.js'
import {Game} from "../../Game";
import {Texture} from "pixi.js";
import {UI_ASSETS} from "../../assetsLoader/UiAssets";

export class GameField {
    game: Game
    container?: PIXI.Container
    background?: PIXI.Sprite
    width: number
    height: number
    constructor(game: Game) {
        this.game = game
        this.width = 1769
        this.height = 1264
    }

    init = () => {
        this.container = new PIXI.Container()
        this.background = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_FIELD))
        this.background.width = 1769
        this.background.height = 1264
        console.log(`background`, this.background)

        this.container.width = this.width
        this.container.height = this.height
        this.container.addChild(this.background)
        this.container.x = 340
        this.container.y = 20
        this.container.scale.set(0.7, 0.7)
        this.game.assetsManager.gameContainer.addChild(this.container)
    }

    /**
     * function for adaptive description
     */
    resize = () => {
        if(!this.container || !this.background) return
    }
}
