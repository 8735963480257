import {SymbolItem} from "./SymbolItem";
import {SYMBOL_SIZE, SYMBOLS} from "../constants/Symbols";
import {Game} from "../Game";
import * as PIXI from 'pixi.js'
import {GameField} from "../components/gameField/GameField";

export class Reel {
    id: number
    symbols: SymbolItem[]
    game: Game
    sheet?: GameField
    reelsContainer: PIXI.Container

    constructor(game: Game, id: number, parentContainer: PIXI.Container) {
        this.id = id
        this.game = game
        this.symbols = []
        this.reelsContainer = parentContainer
    }

    init = () => {
        if (!this.game.assetsManager.gameField) return
        this.sheet = this.game.assetsManager.gameField
    }

    generateSymbols = (symbols: string[]) => {
        return new Promise((resolve, reject) => {
            symbols.forEach((name) => {
                const baseSymbol = SYMBOLS.find((symbol) => symbol.name === name)
                if (!baseSymbol) return reject(`Can't find symbol with id - ${name}`)

                const symbol = new SymbolItem(baseSymbol.name, baseSymbol.name, baseSymbol.asset)
                this.symbols.push(symbol)

            })
            resolve(true)
        })

    }

    placeSymbols = (reelId: number, isInitial = true) => {
        return new Promise((resolve) => {

            const startY = this.symbols.length === 3 ? SYMBOL_SIZE + 4 : 0
            this.symbols.forEach((symbol, index) => {
                symbol.sprite.y = isInitial ? startY + (SYMBOL_SIZE * index) + ((index + 1) * 4) : (startY + (SYMBOL_SIZE * index) + ((index + 1) * 4)) - 1260
                symbol.sprite.x = SYMBOL_SIZE * reelId + ((reelId + 1) * 4)
                this.reelsContainer?.addChild(symbol.sprite)

            })

            return resolve(true)
        })
    }

    clearWinBorder = () => {
        return new Promise((resolve) => {

            this.symbols.forEach((symbol) => {
                symbol.sprite.removeChildren();
            })

            return resolve(true)
        })
    }

    //THIS FUNCTION MOVES ELEMENTS TO TOP POSITION
    replaceTopTop = (reelId: number) => {
        return new Promise((resolve, reject) => {
            if (!this.sheet) return reject(`Can't find game field`)

            const startY = this.symbols.length === 3 ? SYMBOL_SIZE + 4 : 0
            this.symbols.forEach((symbol, index) => {
                symbol.sprite.y = (startY + (SYMBOL_SIZE * index) + ((index + 1) * 4)) - 1260
                symbol.sprite.x = SYMBOL_SIZE * reelId + ((reelId + 1) * 4)
                symbol.sprite.zIndex = 1
            })

            return resolve(true)
        })
    }
}
