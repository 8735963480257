import {Loader} from "pixi.js";
import GameSheetImg from '../assets/sheet.png'
import SheetMask from '../assets/sheetMask.png'
import InfoBg from '../assets/info-bar/infoBG.svg'

export enum UI_ASSETS {
    GAME_FIELD = 'GAME_FIELD',
    SHEET_MASK = "SHEET_MASK",
    INFO_BACKGROUND = "INFO_BACKGROUND"
}

export const loadUiAssets = (loader: Loader) => {
    loader.add(UI_ASSETS.GAME_FIELD,  GameSheetImg)
    loader.add(UI_ASSETS.SHEET_MASK, SheetMask)
    loader.add(UI_ASSETS.INFO_BACKGROUND, InfoBg)
}
