import {Container, Graphics, Text} from "pixi.js";
import {Game} from "../../Game";
import {bonusLabelHoverStyles, infoLabelStylesPortret, tipLabel} from "../../styles";

export class TotalWinModal {
    container: Container
    game: Game
    title: Text
    fsCount: Text
    tipLabel: Text
    modalContainer: Container
    modal: Graphics

    constructor(game: Game) {
        this.game = game
        this.container = new Container()
        this.container.name = "MODAL_OVERLAY"
        this.container.zIndex = 1000
        this.container.visible = false
        this.title = new Text('Congratulations\nYou Won', bonusLabelHoverStyles)
        this.fsCount = new Text(`0\nUSD`, infoLabelStylesPortret)
        this.tipLabel = new Text('Click anywhere to continue', tipLabel)
        this.modal = new Graphics()
        this.modalContainer = new Container()
    }

    init = () => {
        this.setupBackground()
        this.setupModal()
        this.game.app.stage.addChild(this.container)
        // document.addEventListener('keydown', (e) => {
        //     if (e.key === '1') this.showModal(true)
        //     else if (e.key === '2') this.showModal(false)
        // })
    }

    setupBackground = () => {
        const graphics = new Graphics()

        graphics.beginFill(0x000000);
        graphics.drawRect(0, 0, 1920, 1080);
        graphics.interactive = true
        graphics.buttonMode = true
        graphics.on('click', () => this.showModal(false))
        graphics.alpha = 0.5
        this.game.assetsManager.gameContainer.interactive = false
        this.container.addChild(graphics)
    }

    setupModal = () => {
        this.modalContainer.name = "FS_MODAL_CONTAINER"
        this.modal.beginFill(0xF1F1F1);
        this.modal.drawRoundedRect(0, 0, 600, 430, 10);
        this.modal.x = (this.container.width - this.modal.width) / 2
        this.modal.y = (this.container.height - this.modal.height) / 2
        this.modalContainer.addChild(this.modal)
        this.container.addChild(this.modalContainer)
        this.setupTextFields()
    }

    setupTextFields = () => {
        this.title.anchor.set(0.5, 0.5)
        this.title.x = this.modal.x + this.modal.width / 2
        this.title.y = (this.modal.y + this.modal.height / 2) - 100
        this.fsCount.anchor.set(0.5, 0.5)
        this.fsCount.x = this.title.x
        this.fsCount.y = this.title.y + 120
        this.tipLabel.anchor.set(0.5, 0.5)
        this.tipLabel.x = this.fsCount.x
        this.tipLabel.y = this.fsCount.y + 140

        this.modalContainer.addChild(this.title)
        this.modalContainer.addChild(this.fsCount)
        this.modalContainer.addChild(this.tipLabel)
    }
    showModal = (value: boolean, winAmount: number = 0, currency: string = 'USD') => {
        if(!this.game.slotMachine) return


        if (value) {
            this.container.visible = true
            this.fsCount.text = `${winAmount}\n${currency}`
            this.tipLabel.text = 'Click anywhere to continue'
        }
        else {
            this.game.slotMachine.isFs = true
            // this.game.slotMachine.onSpin()
            this.container.visible = false
        }
    }
}

