import {Application} from "pixi.js";
import * as PIXI from 'pixi.js'
import {assetsManager} from "./classes/assetsManager";
import {assetsLoader} from "./assetsLoader/assetsLoader";
import {SlotMachine} from "./classes/SlotMachine";
import {api} from "../App";
import {ApiResponse, PlayResponse} from "../api/types";
import {EventType, getEvent} from "../EventBus";

const Window = window as any
Window.PIXI = PIXI

const ratio = 1920 / 1080
export class Game {
    isAssetsLoaded: boolean
    isInit: boolean
    app: Application
    ref: any
    assetsManager: assetsManager
    resizeCallbacks: any[]
    slotMachine?: SlotMachine
    initData?: ApiResponse<PlayResponse>
    isDebugReels: boolean
    constructor() {
        this.isAssetsLoaded = false
        this.isInit = false
        this.app = new PIXI.Application({
            width: 1920,
            height: 1080,
            // resizeTo: document.body,
            backgroundColor: 0x559fad
        })
        this.assetsManager = new assetsManager(this)
        this.resizeCallbacks = []
        this.isDebugReels = false
    }

    async init(ref: any) {
        if(this.isInit) return
        this.isInit = true
        this.ref = ref
        this.initData = await api.auth.initialize()
        await assetsLoader(this)
        await this.assetsManager.initAllComponents()
        console.log(`assets is loaded`, this.isAssetsLoaded)
        ref.current.appendChild(this.app.view)
        this.resize(this)

        window.addEventListener('resize', () => this.resize(this))
        this.slotMachine = new SlotMachine(this)
        this.slotMachine.balance = this.initData?.results[0].clientData.account.value
        await this.slotMachine.init()
        if(this.initData?.playerState.playerStatePrivate.freeSpinCount > 0) {
            this.slotMachine.isFs = true
            this.assetsManager.FsModal.showModal(true, this.initData?.playerState.playerStatePrivate.freeSpinCount)
        }
        getEvent(EventType.GAME_ASSETS_LOADED).send(true)




        // await this.slotMachine.onSpin()
        this.app.start()
    }

    resize(game: this) {
        if(!game.app) return

        // TODO: WHEN DESIGN WILL BE DEVELOPED USE THIS FUNCTIONS FOR ADAPTIVE
        // this.resizeCallbacks.forEach((callback) => {
        //     callback()
        // })

        console.log(`resize`, this.app)

        if (window.innerWidth / window.innerHeight >= ratio) {
            var w = window.innerHeight * ratio;
            var h = window.innerHeight;
        } else {
            var w = window.innerWidth;
            var h = window.innerWidth / ratio;
        }
        game.app.view.style.width = w + 'px';
        game.app.view.style.height = h + 'px';

        // this.app.resize()
    }

}
