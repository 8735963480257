import {FC, useCallback, useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {Game} from "../../game/Game";
import {EventType, getEvent} from "../../EventBus";
import {useForm} from "@mantine/form";


interface IProps {
    game: Game
}

const reels = localStorage.getItem('debugReels')


const reelsValue = JSON.parse(reels!)
const DebugPlayModal: FC<IProps> = ({game}) => {
    const [isModalActive, setModalActive] = useState(false)

    const form = useForm({
        initialValues: {
            positions: reelsValue || '',
            hiddenExchange: 1
        },
        validate: {
            positions: value => (value.length < 1 ? 'Cannot be empty' : null),
        },
    });

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if(e.code === "KeyE") {
                setModalActive(!isModalActive)
            } else if (e.code === 'KeyD') {
                game.isDebugReels = !game.isDebugReels
                getEvent(EventType.IS_DEBUG).send(game.isDebugReels)
            }
        }
        document.addEventListener('keydown', handler)
        return () => document.removeEventListener('keydown', handler)
    })


    const setDebugReels = useCallback(async(values: any) => {
        localStorage.setItem('debugReels', JSON.stringify(values.positions));
        localStorage.setItem('hiddenExchange', JSON.stringify(values.hiddenExchange));
        setModalActive(false)
        console.log(`SPIN`)
        await game.slotMachine?.onSpin()
    }, [game, setModalActive])

    const cleanDebugReels = () => {
        localStorage.removeItem('debugReels');
    };

    return (
        <Overlay style={{display: `${isModalActive ? 'flex': 'none'}`}}>
            <Form onSubmit={form.onSubmit(setDebugReels)}>
                <FormTitle>Try your luck</FormTitle>
                <FormInput placeholder='Positions' {...form.getInputProps('positions')} />
                <FormInput placeholder='Hidden change to' {...form.getInputProps('hiddenExchange')} />
                <ButtonsWrapper>
                    <SubmitButton style={{marginRight: 8}} type='submit'>
                        Start
                    </SubmitButton>
                    <SubmitButton style={{marginLeft: 8}} onClick={cleanDebugReels}>Remove</SubmitButton>
                </ButtonsWrapper>
            </Form>
        </Overlay>
    );
};

export default DebugPlayModal;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000080;
  width: 100%;
  height: 100%;
`

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 200px;
  background-color: #170c26;
  padding: 25px;
  border-radius: 8px;
`;

const FormTitle = styled.h2`
  font-family: 'Rubik', sans-serif;
  text-align: center;
  padding-bottom: 10px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
`;

const FormInput = styled.input`
  position: relative;
  height: 36px;
  background-color: #ffffff;
  outline: none;
  border-radius: 8px;
  border: none;
  margin-block: 8px;
  padding: 6px 12px;
  font-weight: 600;
  color: black;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  border: none;
  border-radius: 8px;
  height: 36px;
`;

const SubmitButton = styled.button`
  font-family: 'Rubik', sans-serif;
  width: 100%;
`;
