import React, {useEffect, useRef, useState} from 'react';
import {Game} from "./game/Game";
import Api from "./api";
import DebugPlayModal from "./features/DebugReelModal";
import {EventType, useEventReceiver} from "./EventBus";

const game = new Game()

const rubik = new FontFace("Rubik", "url(https://fonts.gstatic.com/s/rubik/v23/iJWKBXyIfDnIV7nBrXw.woff2)", {weight: '300 600'});
document.fonts.add(rubik);
rubik.load()

export const api = new Api()

function App() {
    const ref = useRef(null)
    const [isAssetsLoaded, setIsAssetsLoaded] = useState(false)

    useEventReceiver<boolean>(EventType.GAME_ASSETS_LOADED, (value) => {
        setIsAssetsLoaded(value)
    })


    useEffect(() => {
        game.init(ref);

        return () => {
            game?.app?.stop();
        };
    }, [ref]);

    return (
        <>

            <div ref={ref}>
            </div>
            {isAssetsLoaded &&
                <DebugPlayModal game={game}/>
            }

        </>
    );
}

export default App;
