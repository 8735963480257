import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import {Application, Texture} from 'pixi.js';
import {Game} from "../../Game";
import {BUTTONS_ASSETS} from "../../assetsLoader/ButtonsLoader";
import {UI_ASSETS} from "../../assetsLoader/UiAssets";
import {infoLabelStyles, infoValueStyles} from "../../styles";

export class BetInput {
    app: Application;
    game: Game;
    label: string;
    width: number;
    value?: string | number;
    input?: PIXI.Container;
    title: PIXI.Text;
    text: PIXI.Text;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    plusButton: PIXI.Container;
    minusButton: PIXI.Container;
    backgroundInput?: PIXI.Sprite;
    texture?: PIXI.Texture;
    plusButtonDefault: PIXI.Sprite;
    plusButtonHover: PIXI.Sprite;
    minusButtonDefault: PIXI.Sprite;
    minusButtonHover: PIXI.Sprite;
    totalBetList: number[];
    selectedBetIndex: number;
    currentBetValue: number;
    betValue: number;

    constructor(
        label: string,
        value: string | number,
        width: number,
        game: Game
    ) {
        this.betValue = Number(value);
        this.totalBetList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.selectedBetIndex = 0;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
        this.app = game.app;
        this.game = game;
        this.plusButton = new PIXI.Container();
        this.minusButton = new PIXI.Container();
        this.label = label;
        this.width = width;
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.plusButtonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLUS_BTN));
        this.plusButtonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLUS_BTN_HOVER));
        this.minusButtonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.MINUS_BTN));
        this.minusButtonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.MINUS_BTN_HOVER));

        this.value = value === 0 ? value.toFixed(2) : `${Number(value).toFixed(2)} USD`;
        this.texture = Texture.from(UI_ASSETS.INFO_BACKGROUND)
        this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
        this.text = new PIXI.Text(this.value, infoValueStyles);
    }

    init = () => {
        this.title.resolution = 2;
        this.text.resolution = 2;
        this.backgroundInput = new PIXI.Sprite(this.texture);
        this.backgroundInput.width = this.width;

        this.backgroundInput.height = 60;

        this.input = new PIXI.Container();
        this.input.width = this.width;
        this.input.height = this.backgroundInput.height;
        this.input.addChild(this.backgroundInput, this.title, this.text);

        this.title.anchor.set(0.5, 0.5);
        this.text.anchor.set(0.5, 0.5);

        this.title.x = this.width / 2;
        this.text.x = this.width / 2;

        this.title.y = 18;
        this.text.y = 38;

        this.initializeMinusButton();
        this.initializePlusButton();

        this.changeButtonsState()
    };

    initializeMinusButton = () => {
        this.minusButton
            .on('pointerup', () => this.doMinusPointerUp())
            .on('pointerupoutside', () => this.doMinusPointerUpOutside())
            .on('touchstart', () => this.doMinusPointerDown())
            .on('click', (e) => this.leftClickMinus(e))
            .on('pointerover', () => this.doMinusPointerOver())
            .on('pointerout', () => this.doMinusPointerOut())
            .on('mousedown', () => this.doMinusPointerDown());

        this.minusButton.interactive = true;
        this.minusButton.buttonMode = true;

        this.minusButton.width = 33;
        this.minusButton.height = 52;

        this.minusButtonHover.alpha = 0;

        this.backgroundInput?.addChild(this.minusButton);
        this.minusButton.addChild(this.minusButtonDefault!);
        this.minusButton.addChild(this.minusButtonHover!);

        this.minusButton.x = 4;
        this.minusButton.y = 4;
    };

    leftClickMinus = (e: any) => {
        if (this.game.slotMachine?.isSpinning === false) {
            if (e.data.button === 0) {
                this.doMinusPointerDown()
            }
            ;
        }
    }

    initializePlusButton = () => {
        this.plusButton
            .on('pointerup', () => this.doPlusPointerUp())
            .on('pointerupoutside', () => this.doPlusPointerUpOutside())
            .on('touchstart', () => this.doPlusPointerDown())
            .on('click', (e) => this.leftClickPlus(e))
            .on('pointerover', () => this.doPlusPointerOver())
            .on('pointerout', () => this.doPlusPointerOut())
            .on('mousedown', () => this.doPlusPointerDown());


        this.plusButton.interactive = true;
        this.plusButton.buttonMode = true;

        this.plusButton.width = 33;
        this.plusButton.height = 52;

        this.plusButtonHover.alpha = 0;

        this.backgroundInput?.addChild(this.plusButton);
        this.plusButton.addChild(this.plusButtonDefault!);
        this.plusButton.addChild(this.plusButtonHover!);

        this.plusButton.x = this.width + 12
        this.plusButton.y = 4;
    };

    leftClickPlus = (e: any) => {
        if (e.data.button === 0) {
            this.doPlusPointerDown()
        }
    }

    doMinusPointerUp() {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.minusButtonHover,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
            wiggleTween.fromTo(
                this.minusButtonDefault,
                0,
                {alpha: 1},
                {alpha: 0, repeat: 0}
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.minusButtonDefault,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
        }
        this.pointerIsDown = false;
        this.changeButtonsState()
    }

    doPlusPointerUp() {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.plusButtonHover,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
            wiggleTween.fromTo(
                this.plusButtonDefault,
                0,
                {alpha: 1},
                {alpha: 0, repeat: 0}
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.plusButtonDefault,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
        }
        this.pointerIsDown = false;
        this.changeButtonsState()
    }

    doMinusPointerUpOutside() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doPlusPointerUpOutside() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doMinusPointerDown() {
        if (!this.game.slotMachine) return
        if (this.pointerIsDown) return
        if (this.selectedBetIndex > 0) {
            this.selectedBetIndex--;
        } else if (this.selectedBetIndex === 0) {
            this.selectedBetIndex = 0
        }

        this.currentBetValue = this.totalBetList[this.selectedBetIndex];

            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.minusButtonHover,
                0,
                {alpha: 1},
                {alpha: 0, repeat: 0}
            );
            wiggleTween.fromTo(
                this.minusButtonDefault,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );

        if (this.currentBetValue <= 0) return
        this.text.text = `${this.currentBetValue.toFixed(2)} USD`;
        this.game.slotMachine.bet = this.currentBetValue

        let wiggleTween2 = gsap.timeline();
        wiggleTween2.fromTo(
            this.minusButtonHover,
            0,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween2.fromTo(
            this.minusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = true;
    }

    doPlusPointerDown() {
        if (this.pointerIsDown || !this.game.slotMachine) return

        if (this.selectedBetIndex < this.totalBetList.length - 1 && this.selectedBetIndex !== this.totalBetList.length - 1) {
            this.selectedBetIndex++;
        }
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.text.text = `${this.currentBetValue.toFixed(2)} USD`;
        this.game.slotMachine.bet = this.currentBetValue

        let wiggleTween2 = gsap.timeline();
        wiggleTween2.fromTo(
            this.plusButtonHover,
            0,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween2.fromTo(
            this.plusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = true;
    }


    doMinusPointerOver() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonHover,
            0.3,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsOver = true;
    }

    doPlusPointerOver() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0.3,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsOver = true;
    }


    doMinusPointerOut() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonHover,
            0.3,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }


    doPlusPointerOut() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0.3,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    changeButtonsState = () => {
        if (this.selectedBetIndex === 0) {
            this.minusButton.alpha = 0.5
            this.minusButton.interactive = false
        } else {
            this.minusButton.alpha = 1
            this.minusButton.interactive = true
        }
        if (this.selectedBetIndex === this.totalBetList.length - 1) {
            this.plusButton.alpha = 0.5
            this.plusButton.interactive = false
        } else {
            this.plusButton.alpha = 1
            this.plusButton.interactive = true
        }
    }

    changeBtnStatus = (status: boolean) => {

            if(!status) {
                this.minusButton.alpha = 0.5
                this.minusButton.interactive = false
                this.plusButton.alpha = 0.5
                this.plusButton.interactive = false
            } else {
                this.minusButton.alpha = 1
                this.minusButton.interactive = true
                this.plusButton.alpha = 1
                this.plusButton.interactive = true
            }
    }

}


