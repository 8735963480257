import {Loader} from "pixi.js";
import Low1 from '../assets/symbols/1_LOW.png'
import Low2 from '../assets/symbols/2_LOW.png'
import Low3 from '../assets/symbols/3_LOW.png'
import Low4 from '../assets/symbols/4_LOW.png'
import Low5 from '../assets/symbols/5_LOW.png'
import High1 from '../assets/symbols/1_HIGH.png'
import High2 from '../assets/symbols/2_HIGH.png'
import High3 from '../assets/symbols/3_HIGH.png'
import High4 from '../assets/symbols/4_HIGH.png'
import High5 from '../assets/symbols/5_HIGH.png'
import Scatter from '../assets/symbols/SCATTER.png'
import Wild from '../assets/symbols/WILD.png'
import Mystery from '../assets/symbols/mystery.png'

export enum SYMBOLS_ASSETS {
    ONE_LOW = 'ONE_LOW',
    TWO_LOW = 'TWO_LOW',
    THREE_LOW = 'THREE_LOW',
    FOUR_LOW = 'FOUR_LOW',
    FIVE_LOW = 'FIVE_LOW',
    ONE_HIGH = 'ONE_HIGH',
    TWO_HIGH = 'TWO_HIGH',
    THREE_HIGH = 'THREE_HIGH',
    FOUR_HIGH = 'FOUR_HIGH',
    FIVE_HIGH = "FIVE_HIGH",
    WILD = "WILD",
    HIDDEN = "HIDDEN",
    SCATTER = "SCATTER"
}

export const loadSymbols = (loader: Loader) => {
    loader.add(SYMBOLS_ASSETS.ONE_LOW, Low1)
    loader.add(SYMBOLS_ASSETS.TWO_LOW, Low2)
    loader.add(SYMBOLS_ASSETS.THREE_LOW, Low3)
    loader.add(SYMBOLS_ASSETS.FOUR_LOW, Low4)
    loader.add(SYMBOLS_ASSETS.FIVE_LOW, Low5)
    loader.add(SYMBOLS_ASSETS.ONE_HIGH, High1)
    loader.add(SYMBOLS_ASSETS.TWO_HIGH, High2)
    loader.add(SYMBOLS_ASSETS.THREE_HIGH, High3)
    loader.add(SYMBOLS_ASSETS.FOUR_HIGH, High4)
    loader.add(SYMBOLS_ASSETS.FIVE_HIGH, High5)
    loader.add(SYMBOLS_ASSETS.SCATTER, Scatter)
    loader.add(SYMBOLS_ASSETS.WILD, Wild)
    loader.add(SYMBOLS_ASSETS.HIDDEN, Mystery)
}
