import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import {Application, Texture} from 'pixi.js';
import {Game} from "../../Game";
import {BUTTONS_ASSETS} from "../../assetsLoader/ButtonsLoader";

class PlayBtn {
    app: Application;
    game: Game;
    button: PIXI.Container;
    buttonBackground: PIXI.Sprite;
    buttonBorder: PIXI.Sprite;
    buttonPlay: PIXI.Sprite;
    buttonPlayHover: PIXI.Sprite;
    buttonStop: PIXI.Sprite;
    buttonStopHover: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    fsCount: PIXI.Text

    constructor(game: Game) {
        this.game = game;
        this.app = game.app
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.button = new PIXI.Container();
        this.buttonBackground = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_BG));
        this.buttonBorder = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_BORDER));
        this.buttonPlay = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_ICON));
        this.buttonPlayHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_ICON_HOVER));
        this.buttonStop = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.STOP_BTN_ICON));
        this.buttonStopHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.STOP_BTN_ICON_HOVER));
        this.fsCount = new PIXI.Text(0, {
            fontFamily: 'Rubik',
            fontSize: 55.5,
            fontWeight: '900',
            fill: '#F9AD29',
            stroke: '#000000',
            strokeThickness: 1,
        })
    }

    init = async () => {
        await this.setupBackgrounds()
        await this.setupBorder()
        await this.setupIcons()
        await this.setupInteractive()

        this.button.x = this.game.assetsManager.gameContainer.width + this.button.width * 2 + 70
        this.button.y = this.game.assetsManager.gameContainer.height / 2
        console.log(`button`, this)
        this.button.zIndex = 100
        this.game.assetsManager.gameContainer.addChild(this.button)

    };

    //<--
    // DESIGN SETUP
    setupBorder = () => {
        this.buttonBorder.x = 0;
        this.buttonBorder.y = 0;
        this.buttonBorder.width = 200;
        this.buttonBorder.height = 200;
        this.buttonBorder.anchor.set(0.5);
        this.button.addChild(this.buttonBorder);


    }

    setupIcons = () => {
        this.buttonPlay.x = 0;
        this.buttonPlay.y = 0;
        this.buttonPlay.anchor.set(0.5);
        this.buttonPlayHover.anchor.set(0.5);
        this.buttonBorder.anchor.set(0.5);
        this.button.addChild(this.fsCount!);
        this.fsCount.anchor.set(0.5, 0.5)
        this.fsCount.x = -2;
        this.fsCount.y = 0;
        this.fsCount.alpha = 0
        this.button.addChild(this.buttonPlay);
        this.button.addChild(this.buttonPlayHover);
    }

    setupBackgrounds = () => {
        this.buttonBackground.y = 0;
        this.buttonBackground.x = 0;
        this.buttonBackground.anchor.set(0.5);
        this.button.addChild(this.buttonBackground);
    }

    // DESIGN
    // -->

    setupInteractive = () => {
        this.button.hitArea = new PIXI.Circle(0, 0, 110)
        this.button.interactive = true
        this.button.buttonMode = true;
        this.button
            .on('pointerup', () => this.doPointerUp())
            .on('pointerupoutside', () => this.doPointerUpOutside())
            .on('touchstart', () => this.doPointerDown())
            .on('click', (e) => this.leftClick(e))
            .on('pointerover', () => this.doPointerOver())
            .on('pointerout', () => this.doPointerOut());
    }

    // <--
    // METHODS

    leftClick = (e: any) => {
        if (e.data.button === 0) {
            this.doPointerDown()
        }
    }

    doPointerUp() {
        if (this.pointerIsOver) {
            const animation = gsap.timeline();
            animation.fromTo(
                this.buttonPlay,
                {alpha: 0},
                {alpha: 1, repeat: 0, duration: 0})

        } else {
            const wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonPlayHover,
                {alpha: 1},
                {alpha: 0, repeat: 0, duration: 0},
            );
        }
        this.pointerIsDown = false;
    }

    doPointerUpOutside() {
        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonPlay,
            {alpha: 0},
            {alpha: 1, repeat: 0, duration: 0}
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doPointerDown() {
        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonPlayHover,
            {alpha: 0},
            {alpha: 1, repeat: 0, duration: 0}
        );
        this.pointerIsDown = true;
        this.game.slotMachine?.onSpin()
    }


    doPointerOver() {
        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonBorder,
            {rotation: this.buttonBorder.rotation},
            {rotation: this.buttonBorder.rotation + Math.PI, repeat: 0, duration: 0.3}
        );
        animation.fromTo(
            this.buttonPlayHover,
            {alpha: 0},
            {alpha: 1, repeat: 0, duration: 0}
        );
        this.pointerIsOver = true;
    }

    doPointerOut() {
        const wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonBorder,
            {rotation: this.buttonBorder.rotation},
            {rotation: this.buttonBorder.rotation - Math.PI, repeat: 0, duration: 0.3}
        );
        wiggleTween.fromTo(
            this.buttonPlayHover,
            {alpha: 1},
            {alpha: 0, repeat: 0, duration: 0}
        );
        wiggleTween.fromTo(
            this.buttonPlay,
            {alpha: 0},
            {alpha: 1, repeat: 0, duration: 0}
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    changeButtonState = (status: boolean) => {
        if (!status) {
            this.button.alpha = 0.5
            this.button.interactive = false
        } else {
            this.button.alpha = 1
            this.button.interactive = true
        }
    }

    changeButtonType = (type: 'fs' | 'default') => {
        if(type === 'fs') {
            this.fsCount.alpha = 1
            this.buttonPlay.alpha = 0
            this.buttonPlayHover.alpha = 0
        } else {
            this.fsCount.alpha = 0
            this.buttonPlay.alpha = 1
        }
    }

    setSpinCount = (value: number | string) => {
        this.fsCount.text = value
    }

    // METHODS
    //-->
}


export default PlayBtn;
