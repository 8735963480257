import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import {Application, Texture} from 'pixi.js';
import {BUTTONS_ASSETS} from "../../assetsLoader/ButtonsLoader";
import {Game} from "../../Game";
import {bonusLabelDisabledStyles, bonusLabelHoverStyles, bonusLabelStyles} from "../../styles";
import {api} from "../../../App";

class BuyBonusButton {
    app: Application;
    game: Game;
    button: PIXI.Container;
    buttonBackground: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    buttonDefault: PIXI.Sprite;
    buttonHover: PIXI.Sprite;
    buttonDisabled: PIXI.Sprite;
    buttonLabel: PIXI.Text;
    buttonLabelHover: PIXI.Text;
    buttonLabelDisabled: PIXI.Text;
    type: 1 | 2

    constructor(game: Game, type: 1 | 2) {
        this.game = game;
        this.type = type
        this.app = game.app;
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.buttonBackground = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_BG));
        this.buttonLabel = new PIXI.Text(`BUY\nBONUS ${type}`, bonusLabelStyles);
        this.buttonLabelHover = new PIXI.Text(`BUY\nBONUS ${type}`, bonusLabelHoverStyles);
        this.buttonLabelDisabled = new PIXI.Text(`BUY\nBONUS ${type}`, bonusLabelDisabledStyles);
        this.buttonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_DEFAULT));
        this.buttonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_HOVER));
        this.buttonDisabled = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_DISABLED));
        this.button = new PIXI.Container();
    }

    initialize = () => {
        this.button.name = "BUY_BONUS_BTN"
        this.button.x = 112 + 100;
        this.button.y = 368 + 80;
        this.button.width = 220;
        this.button.height = 220;

        this.buttonBackground.x = 0;
        this.buttonBackground.y = 0;
        this.buttonBackground.width = 220;
        this.buttonBackground.height = 220;

        this.button.interactive = true;
        this.button.buttonMode = true;

        this.buttonHover.alpha = 0;
        this.buttonDisabled.alpha = 0;

        this.buttonLabel.anchor.set(0.5);
        this.buttonLabelHover.anchor.set(0.5);
        this.buttonLabelDisabled.anchor.set(0.5);
        this.buttonBackground.anchor.set(0.5);
        this.buttonDefault.anchor.set(0.5);
        this.buttonHover.anchor.set(0.5);
        this.buttonDisabled.anchor.set(0.5);

        this.button.addChild(this.buttonBackground!);
        this.buttonBackground.addChild(this.buttonDefault!);
        this.buttonBackground.addChild(this.buttonHover!);
        this.buttonBackground.addChild(this.buttonDisabled!);
        this.buttonDefault.addChild(this.buttonLabel!);
        this.buttonHover.addChild(this.buttonLabelHover!);
        this.buttonDisabled.addChild(this.buttonLabelDisabled!);

        this.game.assetsManager.gameContainer.addChild(this.button)

        this.button
            .on('pointerup', () => this.doPointerUp(this.app))
            .on('pointerupoutside', () => this.doPointerUpOutside(this.app))
            .on('touchstart', () => this.doPointerDown(this.app))
            .on('click', (e) => this.leftClick(e))
            .on('pointerover', () => this.doPointerOver(this.app))
            .on('pointerout', () => this.doPointerOut(this.app));
    };

    leftClick = (e:any) => {
        if(e.data.button === 0){
            this.doPointerDown(this.app)
        };
    }

    doPointerUp(app: Application) {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonHover,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
            wiggleTween.fromTo(
                this.buttonDefault,
                0,
                { alpha: 1 },
                { alpha: 0, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonDefault,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
    }

    doPointerUpOutside(app: Application) {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    async doPointerDown(app: Application) {

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.buttonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if(!this.game.slotMachine) return
        const bonusCost = this.game.slotMachine.bet * (this.type === 1? 100 : 330)
        console.log(`BONUS COST IS`, bonusCost)
        console.log(`CURRENT BALANCE`, this.game.slotMachine.balance)
        this.game.slotMachine.balance -= bonusCost - this.game.slotMachine.bet
        const data = await api.auth.buyBonus(this.game.slotMachine?.bet!, this.type)
        await this.game.slotMachine?.onSpin(data)
        this.pointerIsDown = true;
    }

    doPointerOver(app: Application) {
        console.log(`pointer over`)
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonHover,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }

    changeButtonState(state: boolean){
        this.button.interactive = state;
        this.button.buttonMode = state;
        this.button.alpha= state ? 1 : 0.5;
    }

    doPointerOut(app: Application) {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonHover,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.buttonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    doDefaultVisible(app: Application) {
        let wiggleTween = gsap.timeline();
        wiggleTween.to(
            this.buttonHover,
            0,
            { alpha: 0 },
        );
        wiggleTween.to(
            this.buttonDefault,
            0,
            { alpha: 1 },
        );
    }

    doButtonDisabled(app: Application) {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonDisabled,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        wiggleTween.to(
            this.buttonDefault,
            0.3,
            { alpha: 0 },
        );
        this.pointerIsOver = true;
    }

    doButtonEnabled(app: Application) {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonDisabled,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.to(
            this.buttonDefault,
            0.3,
            { alpha: 1 },
        );
        this.pointerIsOver = true;
    }

    setBuyBonusButtonState(state: boolean){
        this.button.interactive = state;
        this.button.buttonMode = state;
    }

    disableButton = (value: boolean) => {
        if(value) {
            this.button.interactive = false
            this.button.alpha = 0.5
        } else {
            this.button.interactive = true
            this.button.alpha = 1
        }
    }
}

export default BuyBonusButton;
