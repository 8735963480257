import {Base} from './Base';
import {ApiResponse, PlayResponse} from "./types";

export default class Public extends Base {

    async play(bet: number, isFreeSpin: boolean, freeSpinCount: number, command: string = "") {
        const payload = {
            stakeValue: {
                cashBet: bet
            },
            playerState: {
                isFreeSpin: isFreeSpin,
                // freeSpinCount: 0
            },
            command: command
        }
        const response = await this.request.post<ApiResponse<PlayResponse>>(
            `/public/play`,
            payload
        );

        return response.data;
    }

    async debug(bet: number) {
        try {
            const reels = JSON.parse(localStorage.getItem('debugReels')!)
            const hiddenExchange = JSON.parse(localStorage.getItem('hiddenExchange')!)
            if(!reels) return
            const payload = {
                stakeValue: {
                    cashBet: bet
                },
                testReels: {
                    state: JSON.parse(reels),
                    hiddenExchange: hiddenExchange || '1',
                },

                command: ""

            }
            console.log(`SPIN`, reels)
            const response = await this.request.post<ApiResponse<PlayResponse>>("/public/debugPlay", payload)

            return response.data

        } catch (e) {
            console.log(e)
        }
}

    async initialize() {
        const response = await this.request.get<ApiResponse<PlayResponse>>(
            `/public/initialize`,
        );

        return response.data;
    }

    async buyBonus(bet: number, type: 1 | 2) {
        const payload = {
            stakeValue: {
                cashBet: bet,
                choiceBonus: type
            },
            playerState: {
                isFreeSpin: false,
                freeSpinCount: 0
            },
            command: ""
        }
        console.log(`PAYLOAD`, payload)
        const response = await this.request.post<ApiResponse<PlayResponse>>(
            '/public/buyBonus',payload
        )

        return response.data
    }
}
