import * as PIXI from 'pixi.js'
import {Texture} from "pixi.js";
import {SYMBOL_SIZE} from "../constants/Symbols";

export class SymbolItem  {
    name: string
    asset: string
    symbolId: string
    sprite: PIXI.Sprite
    constructor(id: string,name: string, asset: string) {
        this.symbolId = id
        this.name = name
        this.asset = asset

        this.sprite = new PIXI.Sprite(Texture.from(asset))
        this.sprite.width = SYMBOL_SIZE
        this.sprite.height = SYMBOL_SIZE
    }

    copy = () => {
        return new SymbolItem(this.symbolId, this.name, this.asset)
    }

    // METHODS EXAMPLE: WIN_ANIM, LANDING_ANIM, DESTRUCTION_ANIM
}
