import {SYMBOLS_ASSETS} from "../assetsLoader/SymbolsLoader";
import {SymbolType} from "../../api/types";

export interface Symbol {
    name: SymbolType,
    asset: string,
    id: string
}

export const Field = [3, 5, 5, 5, 5, 5, 3]
export const TEST_SYMBOLS: SymbolType[][] = [
    ["H1", "H2", "H3"],
    ["H4", "H5", "A", "K", "Q"],
    ["K", "Q", "Q", "A", "A"],
    ["10", "10", "HIDDEN", "H1", "H1"],
    ["HIDDEN", "A", "Q", "J", "H2"],
    ["H3", "H2", "Q", "K", "H2"],
    ["H4", "H1", "H5"]
]

export const SYMBOL_SIZE = 248


export const SYMBOLS: Symbol[] = [
    {
        name: "SCATTER",
        asset: SYMBOLS_ASSETS.SCATTER,
        id: "0",
    },
    {
        name: "WILD",
        asset: SYMBOLS_ASSETS.WILD,
        id: "1",
    },
    {
        name: "H1",
        asset: SYMBOLS_ASSETS.ONE_HIGH,
        id: "2",
    },
    {
        name: "H2",
        asset: SYMBOLS_ASSETS.TWO_HIGH,
        id: "3",
    },
    {
        name: "H3",
        asset: SYMBOLS_ASSETS.THREE_HIGH,
        id: "4",
    },
    {
        name: "H4",
        asset: SYMBOLS_ASSETS.FOUR_HIGH,
        id: "5",
    },
    {
        name: "H5",
        asset: SYMBOLS_ASSETS.FIVE_HIGH,
        id: "6",
    },
    {
        name: "A",
        asset: SYMBOLS_ASSETS.ONE_LOW,
        id: "7",
    },
    {
        name: "K",
        asset: SYMBOLS_ASSETS.TWO_LOW,
        id: "8",
    },
    {
        name: "Q",
        asset: SYMBOLS_ASSETS.THREE_LOW,
        id: "9",
    },
    {
        name: "J",
        asset: SYMBOLS_ASSETS.FOUR_LOW,
        id: "10",
    },
    {
        name: "10",
        asset: SYMBOLS_ASSETS.FIVE_LOW,
        id: "11",
    },
    {
        name: "HIDDEN",
        asset: SYMBOLS_ASSETS.HIDDEN,
        id: "12",
    },
]
