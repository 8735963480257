import * as PIXI from 'pixi.js'
import {Game} from "../Game";
import {TextBar} from "../components/TextBar/TextBar";
import {BetInput} from "../components/BetInput/BetInput";

export class InfoBar {
    game: Game
    container: PIXI.Container
    balanceBar: TextBar
    winBar: TextBar
    betBar: BetInput

    constructor(game: Game) {
        this.game = game
        this.container = new PIXI.Container()
        this.balanceBar = new TextBar("BALANCE", 1000, 300, game)
        this.winBar = new TextBar("WIN", 0, 250, game)
        this.betBar = new BetInput('BET', 1, 250, game)
    }

    init = async () => {

        await this.winBar.init()
        await this.balanceBar.init()
        await this.betBar.init()
        await this.betBar.init()

        this.container.addChild(this.balanceBar.input!)
        this.container.addChild(this.winBar.input!)
        this.container.addChild(this.betBar.input!)
        this.game.assetsManager.gameContainer.addChild(this.container)

        this.container.x = (this.game.app.stage.width / 2) - this.container.width + 30
        this.container.y = this.game.app.stage.height + 40

        const baseBalance = this.game.initData?.results[0].clientData.account.value
        if(baseBalance) {
            this.balanceBar.changeValue(baseBalance)
        }

        if(this.winBar.input && this.balanceBar.input && this.betBar.input) {
            this.betBar.input.x = 0
            this.winBar.input.x = this.betBar.input.x + this.winBar.width + 40
            this.balanceBar.input.x = this.winBar.input.x + this.winBar.width + 40


        }
    }
}
