import {Game} from "../Game";
import {GameField} from "../components/gameField/GameField";
import * as PIXI from 'pixi.js'
import {InfoBar} from "./InfoBar";
import PlayBtn from "../components/PlayBtn/PlayBtn";
import {Multiplier} from "./multiplier";
import {FsModal} from "../components/FsModal/FsModal";
import BuyBonusButton from "../components/BuyBonusBtn/BuyBonusBtn";
import {TotalWinModal} from "../components/TotalWinModal/TotalWinModal";

export class assetsManager {
    game: Game
    gameField?: GameField
    gameContainer: PIXI.Container
    infoBar?: InfoBar
    playBtn?: PlayBtn
    multiplier: Multiplier
    FsModal: FsModal
    BuyBonus1?: BuyBonusButton
    BuyBonus2?: BuyBonusButton
    totalWinModal: TotalWinModal
    constructor(game: Game) {
        this.game = game
        this.gameContainer = new PIXI.Container()
        this.gameContainer.width = 1920
        this.gameContainer.height = 1080
        this.gameContainer.sortableChildren = true
        this.multiplier = new Multiplier(game)
        this.FsModal = new FsModal(game)
        this.totalWinModal = new TotalWinModal(game)

    }

    initAllComponents = async () => {
        this.gameField = new GameField(this.game)
        await this.gameField.init()
        this.game.app.stage.addChild(this.gameContainer)
        this.infoBar = new InfoBar(this.game)
        await this.infoBar.init()
        this.playBtn = new PlayBtn(this.game)
        await this.playBtn.init()
        await this.multiplier.init()
        await this.FsModal.init()
        await this.totalWinModal.init()

        this.BuyBonus1 = new BuyBonusButton(this.game, 1)
        this.BuyBonus2 = new BuyBonusButton(this.game, 2)

        await this.BuyBonus2.initialize()
        await this.BuyBonus1.initialize()

        this.BuyBonus2.button.y += 150
        this.BuyBonus1.button.y -= 150

    }

    changeButtonsState = (status: boolean) => {
            this.playBtn?.changeButtonState(status)
            this.infoBar?.betBar.changeBtnStatus(status)
    }
}
