import * as PIXI from 'pixi.js';
import {Texture} from 'pixi.js';
import {Game} from "../../Game";
import {infoLabelStyles, infoValueStyles} from "../../styles";
import {UI_ASSETS} from "../../assetsLoader/UiAssets";


export class TextBar {
    game: Game;
    label: string;
    width: number;
    value?: string | number;
    input?: PIXI.Container;
    title: PIXI.Text;
    text: PIXI.Text;
    backgroundInput?: PIXI.Sprite;
    texture?: PIXI.Texture;
    betValue: number;

    constructor(
        label: string,
        value: string | number,
        width: number,
        game: Game
    ) {
        this.betValue = Number(value);
        this.game = game;
        this.label = label;
        this.width = width;

        this.value = value === 0 ? value : `${value} USD`;
        this.texture = Texture.from(UI_ASSETS.INFO_BACKGROUND)
        this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
        this.text = new PIXI.Text(this.value, infoValueStyles);
    }

    init = () => {
        this.title.resolution = 2;
        this.text.resolution = 2;
        this.title.width = this.title.width * 1.2;
        this.backgroundInput = new PIXI.Sprite(this.texture);
        this.backgroundInput.width = this.width;

        this.backgroundInput.height = 60;

        this.input = new PIXI.Container();
        this.input.width = this.width;
        this.input.height = this.backgroundInput.height;
        this.input.addChild(this.backgroundInput, this.title, this.text);

        this.title.anchor.set(0.5, 0.5);
        this.text.anchor.set(0.5, 0.5);

        this.title.x = this.width / 2;
        this.text.x = this.width / 2;

        this.title.y = 18;
        this.text.y = 38;

    };

    changeValue = (value: number) => {
        this.text.text = `${value.toFixed(2)} USD`;
    };


}

