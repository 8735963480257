import {Game} from "../Game";
import {loadSymbols} from "./SymbolsLoader";
import {loadUiAssets} from "./UiAssets";
import {loadButtonsAssets} from "./ButtonsLoader";


export const assetsLoader  = (game: Game) => {
    return new Promise((resolve) => {
        const loader = game.app.loader
        loadSymbols(loader)
        loadUiAssets(loader)
        loadButtonsAssets(loader)
        loader.load()
        loader.onComplete.add(() => {
            console.log(`loaded`)
            game.isAssetsLoaded = true
            return resolve(true)
        })
    })
}
