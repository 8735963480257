import packageInfo from "../../../package.json";
import styled from "@emotion/styled";
import {appConfig} from "../../config";
import {useState} from "react";
import {EventType, useEventReceiver} from "../../EventBus";

const Version = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #00D700;
  border-top-right-radius: 8px;
  z-index: 9999;
  padding: 2px 8px

`
export default function AppVersion() {
    const [isDebug, setIsDebug] = useState(false)

    useEventReceiver<boolean>(EventType.IS_DEBUG, (value) => {
        setIsDebug(value)
    })

    console.info("APP VERSION:", `v${packageInfo.version}`, 'math-3.0.0')
    if (!appConfig.showVersion) return null

    return (
        <Version>
            Version: v{packageInfo.version}-math3.0.0{isDebug ? `-debug` : ''}
        </Version>)
}

