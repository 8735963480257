import axios from 'axios';
import Public from './Public';

export default class Api {
	auth: Public;

	constructor() {
		this.auth = new Public(this.request);
	}
	// // инстанс запросов через который буду выполятся все запросы на бекенд
	get request() {
		const instance = axios.create({ baseURL: 'https://rome-artroadslots.fgp-dev.me/api/v1' });

		instance.interceptors.request.use(config => {
			// TODO: get token from storage
			// if (token) {
			// 	(config.headers as any).common['Authorization'] = `Bearer ${token}`;
			// }

			return config;
		});

		return instance;
	}
}
